import classNames from 'classnames'
import React, { lazy, Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import { SuspendedState } from '../../../../dashboard/components/SuspendedState'
import { WhiteboardPreviewMode } from '../../../../dashboard/components/Whiteboard/WhiteboardPreviewMode'

const Excalidraw = lazy(
  () => import('../../../../dashboard/components/Whiteboard/Excalidraw/Excalidraw')
)

export const DrawingTab: React.FC<{ hidden: boolean }> = ({ hidden }) => {
  const { darkColorScheme } = useSelector((state) => state.editorSettings)
  // For WBEngine powered Whiteboard component only:
  // Effect to trigger window resize event when the drawing tab visibility changes. Since the tab is initially
  // hidden, Whiteboard is rendered with no size. When it becomes shown, a resize event will trigger Whiteboard
  // to resize its canvases and fill the tab area.
  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [hidden])

  const { drawingImageUrl, drawingModified, drawingBoardId, slug } = usePadConfigValues(
    'drawingImageUrl',
    'drawingModified',
    'drawingBoardId',
    'slug'
  )

  if (drawingBoardId) {
    // WBEngine.
    return (
      <div style={{ height: '100%' }} className={classNames('DrawingTab', { hidden })}>
        <WhiteboardPreviewMode drawingBoardId={drawingBoardId} darkColorScheme={darkColorScheme} />
      </div>
    )
  } else if (drawingImageUrl) {
    // Ultra legacy AwwApp. Nobody can be generating these anymore, so just show the image without accounting for
    // a loading/generating state.
    return (
      <div className={classNames({ hidden, DrawingTab: true })}>
        <img className="Drawing-exportedImage" src={drawingImageUrl} />
      </div>
    )
  } else if (drawingModified) {
    // Excalidraw.
    return (
      <Suspense fallback={<SuspendedState />}>
        <Excalidraw
          slug={slug}
          name="playback"
          readOnly={true}
          whiteboardBoardIdToCopy={undefined}
        />
      </Suspense>
    )
  }

  return <div className={classNames({ hidden, DrawingTab: true })}>No drawing available</div>
}
