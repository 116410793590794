import { Gift } from '@codinpad/shared-components/components/icons/index.js'
import { Box, Chip, styled, ThemeProvider } from '@mui/material'
import { usePadSummary } from 'graphql/hooks/usePadSummary/usePadSummary'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import React, { FC, useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeMode, useTheme } from 'theme/theme'

import { isErrorState, isInitialState, isLoadingState } from '../../../graphql/queryStates'
import { PadAnalyticsEvent } from '../constants'
import { useFrames } from '../playback/hooks/useFrames'
import { ScrollView } from '../ScrollView/ScrollView'
import trackEvent from '../track_event'
import { CopyButton } from './components/CopyButton'
import { FailedToGenerateSummary } from './components/FailedToGenerateSummary'
import { OutlineChapter } from './components/OutlineChapter'
import { SummaryLoading } from './components/SummaryLoading'

interface PadSummaryOutlineProps {
  hidden: boolean
}

const CalloutChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.tokens.surface.brand1,
  color: theme.palette.tokens.text.body1,
  padding: theme.spacing(1.5),
}))

export const PadSummaryOutline: FC<React.PropsWithChildren<PadSummaryOutlineProps>> = ({
  hidden,
}) => {
  const dispatch = useDispatch()
  const { slug } = usePadConfigValues('slug')
  const { padSummary, status } = usePadSummary(slug)
  const { frameIndex, frames } = useFrames()
  const isPadDarkTheme = useSelector((state) => state.editorSettings.darkColorScheme)
  const chipTheme = useTheme(isPadDarkTheme ? ThemeMode.Light : ThemeMode.Dark)
  const outlineRef = useRef<HTMLDivElement>(null)
  const currentTimestamp = frames[frameIndex]?.timestamp
  const activeChapter = useMemo(() => {
    if (padSummary?.outline == null) return undefined

    const index = padSummary.outline.findLastIndex(
      (chapter) => chapter.highlights[0]?.startTs <= currentTimestamp
    )
    return padSummary.outline[index] ?? padSummary.outline[0]
  }, [currentTimestamp, padSummary?.outline])

  const activeHighlight = useMemo(() => {
    if (activeChapter == null) return undefined

    const index = activeChapter?.highlights.findLastIndex(
      (highlight) => highlight.startTs <= currentTimestamp
    )
    return activeChapter.highlights[index] ?? activeChapter.highlights[0]
  }, [activeChapter, currentTimestamp])

  const handleSeekToTimestamp = useCallback(
    (ts: number) => {
      const frame = frames.findLastIndex((f) => f.timestamp <= ts)
      dispatch({ type: 'playback_seek_to_frame', index: frame })
    },
    [dispatch, frames]
  )

  if (hidden) {
    return null
  }

  return (
    <Box sx={{ height: '100%', boxSizing: 'boder-box' }}>
      <ScrollView>
        {isLoadingState(status) || isInitialState(status) ? (
          <SummaryLoading />
        ) : isErrorState(status) ? (
          <FailedToGenerateSummary />
        ) : (
          <div>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={1.5}
              mb={1.5}
              px={3}
            >
              <CopyButton
                message="Copy All"
                text={() => outlineRef.current?.innerText ?? ''}
                onClick={() => trackEvent(PadAnalyticsEvent.PadSummaryOutlineCopyButtonClicked)}
              />
              <ThemeProvider theme={chipTheme}>
                <CalloutChip
                  variant="outlined"
                  color="primary"
                  label="Free for a limited time"
                  size="small"
                  icon={<Gift />}
                />
              </ThemeProvider>
            </Box>
            <Box ref={outlineRef}>
              {padSummary?.outline?.map((chapter, i) => (
                <OutlineChapter
                  chapter={chapter}
                  activeChapter={activeChapter}
                  activeHighlight={activeHighlight}
                  key={i}
                  handleSeekToTimestamp={handleSeekToTimestamp}
                />
              ))}
            </Box>
          </div>
        )}
      </ScrollView>
    </Box>
  )
}
