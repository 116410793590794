import { Box } from '@mui/material'
import { SuspendedState } from 'packs/dashboard/components/SuspendedState'
import { MonacoProvider } from 'packs/main/Monaco/EnvironmentMonaco/MonacoContext'
import React, { lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'

import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'
import { Pane, SplitPane } from '../../../dashboard/components/SplitPane/SplitPane'
import { Whiteboard } from '../../../dashboard/components/Whiteboard/Whiteboard'
import { WhiteboardPreviewMode } from '../../../dashboard/components/Whiteboard/WhiteboardPreviewMode'
import CodePane from '../../code_pane'
import { useActiveEnvironment } from '../../Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { RightPane } from '../../RightPane/RightPane'
import { selectHasRightPane, selectMyUserInfo } from '../../selectors'
import { useFirebaseUsers } from '../CodeSpace/hooks/useFirebaseUsers'

const Excalidraw = lazy(() => import('packs/dashboard/components/Whiteboard/Excalidraw/Excalidraw'))

/**
 * A workspace rendering a split pane with drawing mode on the left and private notes, etc... on the right.
 */
export const DrawingSpace: React.FC<React.PropsWithChildren<unknown>> = () => {
  const hasRightPane = useSelector(selectHasRightPane)
  const darkColorScheme = useSelector((state) => state.editorSettings.darkColorScheme)
  const { slug, isPlayback, drawingBoardId, excalidrawEnabled } = usePadConfigValues(
    'slug',
    'isPlayback',
    'drawingBoardId',
    'excalidrawEnabled'
  )
  const { question } = useActiveEnvironment()

  useFirebaseUsers()

  const { name } = useSelector(selectMyUserInfo)

  return (
    <Box height="100%">
      <MonacoProvider>
        <SplitPane orientation="x">
          <Pane initialSizePct={75} minSize={500} id="left-pane">
            {isPlayback ? (
              // TODO: implement excalidraw in playback mode
              <WhiteboardPreviewMode
                drawingBoardId={drawingBoardId}
                darkColorScheme={darkColorScheme}
              />
            ) : excalidrawEnabled ? (
              <Suspense fallback={<SuspendedState />}>
                <Excalidraw
                  name={name}
                  slug={slug}
                  whiteboardBoardIdToCopy={
                    excalidrawEnabled && question ? question?.drawingBoardId : undefined
                  }
                />
              </Suspense>
            ) : (
              <Whiteboard authorId={window.padConfig?.firebaseAuthorId} {...window.padConfig} />
            )}
          </Pane>
          {hasRightPane ? (
            <Pane minSize={150} id="right-pane">
              <RightPane />
            </Pane>
          ) : null}
        </SplitPane>
        {/* Need to include the editor, but hidden, so that Firepad can initialize and sync user data */}
        {/* Note(BW): visibility="hidden" does not trigger .not.toBeVisible() in tests for some reason, but style={...} does */}
        <Box style={{ visibility: 'hidden' }} width={0} height={0} overflow="hidden">
          <CodePane width={'0px'} handleOpenDrawingMode={() => null} />
        </Box>
      </MonacoProvider>
    </Box>
  )
}
