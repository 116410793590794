import { Box, List, ListItem, styled } from '@mui/material'
import { OutlineChapter as OutlineChapterType, OutlineChapterHighlight } from 'graphql/types'
import React, { FC, useCallback } from 'react'

import { displayDuration } from '../utils'

interface OutlineChapterProps {
  chapter: OutlineChapterType
  activeChapter?: OutlineChapterType
  activeHighlight?: OutlineChapterHighlight
  handleSeekToTimestamp: (timestamp: number) => void
}

const ChapterBox = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  padding: theme.spacing(2),
}))

const ActiveChapterBox = styled(ChapterBox)(({ theme }) => ({
  background: theme.palette.background['400'],
  borderLeftColor: theme.palette.primary.main,
  borderLeftWidth: 3,
  borderLeftStyle: 'solid',
  borderTopRightRadius: theme.spacing(0.5),
  borderBottomRightRadius: theme.spacing(0.5),
}))

const HighlightsList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(0),
}))

const HighlightItem = styled(ListItem)(({ theme }) => ({
  display: 'list-item',
  paddingLeft: theme.spacing(1),
  cursor: 'pointer',

  '&:hover': {
    background: theme.palette.background['500'],
    borderRadius: theme.spacing(0.5),
  },
}))

const ActiveHighlightItem = styled(HighlightItem)(({ theme }) => ({
  background: theme.palette.background['800'],
  borderRadius: theme.spacing(0.5),
}))

const ListItemDisc = styled(Box)(({ theme }) => ({
  fontSize: '1.4rem',
  lineHeight: '1.1rem',
  paddingRight: theme.spacing(1),
}))

export const OutlineChapter: FC<React.PropsWithChildren<OutlineChapterProps>> = ({
  chapter,
  activeChapter,
  activeHighlight,
  handleSeekToTimestamp,
}) => {
  const handleHighlightClick = useCallback(
    (highlight: OutlineChapterHighlight) => {
      handleSeekToTimestamp(highlight.startTs)
    },
    [handleSeekToTimestamp]
  )

  const ChapterComponent = activeChapter == chapter ? ActiveChapterBox : ChapterBox

  return (
    <ChapterComponent>
      {chapter.title}
      {chapter.finalTs != null && chapter.startTs != null
        ? ` - ${displayDuration(chapter.finalTs - chapter.startTs)}`
        : ''}
      <HighlightsList>
        {chapter.highlights.map((highlight, j) => {
          const HighlightComponent =
            activeHighlight == highlight ? ActiveHighlightItem : HighlightItem
          return (
            <HighlightComponent key={j} onClick={() => handleHighlightClick(highlight)}>
              <Box display="flex" flexDirection="row">
                <ListItemDisc>&bull;</ListItemDisc>
                <Box>{highlight.text}</Box>
              </Box>
            </HighlightComponent>
          )
        })}
      </HighlightsList>
    </ChapterComponent>
  )
}
