import firebase from 'firebase/compat/app'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'

import { usePadConfigValues } from '../../../../dashboard/components/PadContext/PadContext'
import SyncHandle from '../../../sync_handle'
import { EnvironmentSummary, EnvironmentTypes, IFirebaseEnvironment } from '../types'

type FirebaseEnvironmentWithId = IFirebaseEnvironment & {
  id: string
}

const environmentSummaryFromFirebaseEnvironment = (e: FirebaseEnvironmentWithId) =>
  ({
    id: e.id,
    slug: e.slug,
    nameDisplayable: e.display,
    language: e.language,
    allowMultipleFiles:
      e.language === 'html' ||
      (!!e.projectTemplateSlug && !e.projectTemplateSlug.startsWith('jupyter')),
    questionId: e.questionId ? e.questionId : undefined,
    isQuestionWithVariants: e.isQuestionWithVariants,
    snippet: e.snippet ? e.snippet : undefined,
    spreadsheet: e.spreadsheet ? e.spreadsheet : undefined,
    projectTemplateSlug: e.projectTemplateSlug,
    projectTemplateVersion: e.projectTemplateVersion,
    customDatabaseLanguage: e.customDatabaseLanguage,
    kind: e.questionId
      ? EnvironmentTypes.Question
      : e.projectTemplateSlug
      ? EnvironmentTypes.Project
      : EnvironmentTypes.Language,
    visible: !e.isPreview,
  } as EnvironmentSummary)

export function useFirebaseEnvironments() {
  const { hasEnvironments } = usePadConfigValues('hasEnvironments')

  const [environments, setEnvironments] = useState<EnvironmentSummary[]>([])
  const [hasLoadedEnvironments, setHasLoadedEnvironments] = useState(false)

  useEffect(() => {
    let watcherCB: (snap: firebase.database.DataSnapshot) => void

    if (hasEnvironments) {
      watcherCB = SyncHandle().watch(
        'environments',
        (fbEnvs: { [environmentId: string]: IFirebaseEnvironment }) => {
          if (fbEnvs != null) {
            setEnvironments((envs) => {
              const newEnvs = Object.keys(fbEnvs)
                .filter((eId) =>
                  envs == null ? true : envs.find((env) => env.slug === fbEnvs[eId].slug) == null
                )
                .map((eId) => ({ ...fbEnvs[eId], id: eId } as FirebaseEnvironmentWithId))
              return envs
                .map((env) => {
                  const fbEnv = { ...fbEnvs[env.id], id: env.id }

                  if (fbEnv == null) return null

                  // ensure the stability of environment objects for downstream consumers by
                  // only emitting a object when an environment property has changed
                  const newEnv = environmentSummaryFromFirebaseEnvironment(fbEnv)
                  return isEqual(env, newEnv) ? env : newEnv
                })
                .filter((e) => e != null && e.slug != null)
                .concat(
                  newEnvs.map(environmentSummaryFromFirebaseEnvironment)
                ) as EnvironmentSummary[]
            })
          } else {
            setEnvironments([])
          }
          setHasLoadedEnvironments(true)
        }
      )
    }
    return () => {
      if (watcherCB != null) {
        SyncHandle().off('environments', watcherCB)
      }
    }
  }, [hasEnvironments])

  return {
    environments,
    hasLoadedEnvironments,
  }
}
