import CloseIcon from '@mui/icons-material/Close'
import LaunchIcon from '@mui/icons-material/Launch'
import { Box, styled, Tooltip } from '@mui/material'
import { selectMyUserInfo } from 'packs/main/selectors'
import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Rnd } from 'react-rnd'

import { useActiveEnvironment } from '../../../main/Environments/ActiveEnvironmentContext/ActiveEnvironmentContext'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { GenericErrorView } from '../GenericErrorView/GenericErrorView'
import { usePadConfigValues } from '../PadContext/PadContext'
import { SuspendedState } from '../SuspendedState'
import { Whiteboard } from './Whiteboard'

const Excalidraw = lazy(() => import('./Excalidraw/Excalidraw'))

const Bounds = styled('div')({
  position: 'absolute',
  width: 'calc(100% - 80px)',
  height: '100%',
  left: 80,
  overflow: 'hidden',
  pointerEvents: 'none',
})

interface IFloatingPanelProps {
  handleClose: () => void
  handleNewWindow: (width?: number, height?: number, zoom?: number, x?: number, y?: number) => void
  isDrawingEnabled: boolean
  isOpen: boolean
}

export const DEFAULT_HEIGHT = 700
export const DEFAULT_WIDTH = 850
const MIN_HEIGHT = 550 // Tall enough to accommodate the drawing toolbar.
const MIN_WIDTH = 550 // A little less than the min width of the code pane.

export const FloatingDrawingBoard: React.FC<React.PropsWithChildren<IFloatingPanelProps>> = ({
  handleClose,
  handleNewWindow,
  isDrawingEnabled,
  isOpen,
}) => {
  const boundsRef = useRef<HTMLDivElement>(null)
  const { slug, excalidrawEnabled } = usePadConfigValues('excalidrawEnabled', 'slug')
  const { name } = useSelector(selectMyUserInfo)
  const { question } = useActiveEnvironment()

  // State used to hide the contents of the floating panel until we have done the initial size/position calculations.
  const [hasInitializedPosition, setHasInitializedPosition] = useState(false)

  // Initial size/position of the floating panel are given sentinel values to indicate they have not been set yet.
  const [coords, setCoords] = React.useState({
    x: -1,
    y: -1,
  })
  const [dims, setDims] = React.useState({
    width: -1,
    height: -1,
  })

  // Whenever the location or dimensions of the floating panel change, dispatch a window resize event to get Aww
  // to recalculate its canvas positions and sizes.
  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [dims.width, dims.height, coords.x, coords.y])

  // Callback to adjust the size of the floating panel when the window itself is resized.
  const adjustPanelSizeLocation = useCallback(() => {
    // Must have a ref to the parent to do size and position calculations.
    if (!boundsRef.current) {
      return
    }

    // Parent must have a size to do the size and position calculations.
    const parentBounds = boundsRef.current.getBoundingClientRect()
    if (parentBounds.width === 0 && parentBounds.height === 0) {
      return
    }

    let { width, height } = dims
    let { x, y } = coords
    let madeAdjustment = false

    if (dims.width === -1 && dims.height === -1 && coords.x === -1 && coords.y === -1) {
      madeAdjustment = true
      x = Math.max((parentBounds.width - DEFAULT_WIDTH) / 2, 80)
      y = Math.max((parentBounds.height - DEFAULT_HEIGHT) / 2, 0)
      width = Math.min(parentBounds.width, DEFAULT_WIDTH)
      height = Math.min(parentBounds.height, DEFAULT_HEIGHT)
      setHasInitializedPosition(true)
    } else if (width > parentBounds.width) {
      // Panel is wider than parent, just pin to left and make it same width as parent.
      width = Math.max(MIN_WIDTH, parentBounds.width)
      x = 0
      madeAdjustment = true
    } else if (width + x > parentBounds.width) {
      // Panel is positioned far enough right it goes off parent. Move it to the left.
      x = parentBounds.width - width
      madeAdjustment = true
    }
    if (height > parentBounds.height) {
      // Panel is taller than parent, pin to top and resize to fit in window.
      height = Math.max(MIN_HEIGHT, parentBounds.height)
      y = 0
      madeAdjustment = true
    } else if (height + y > parentBounds.height) {
      // Panel is far enough down it is off parent. Move it up.
      y = parentBounds.height - height
      madeAdjustment = true
    }
    // If adjustments to the position/size are necessary, make them.
    if (madeAdjustment) {
      setDims({ width, height })
      setCoords({ x, y })
    }
  }, [boundsRef, coords, dims])

  // When the board opens, make sure the panel is properly sized and positioned.
  useEffect(() => {
    if (isOpen) {
      adjustPanelSizeLocation()
    }
  }, [adjustPanelSizeLocation, isOpen])

  // On window resizes, adjust the panel size/location if necessary. This should set the initial
  // size/position in the case that this is the first time drawing mode is being shown.
  useEffect(() => {
    window.addEventListener('resize', adjustPanelSizeLocation)
    return () => {
      window.removeEventListener('resize', adjustPanelSizeLocation)
    }
  }, [adjustPanelSizeLocation])

  return (
    <Bounds ref={boundsRef}>
      <Rnd
        position={coords}
        size={dims}
        minHeight={MIN_HEIGHT}
        minWidth={MIN_WIDTH}
        bounds="parent"
        className="FloatingDrawingBoard"
        dragHandleClassName="FloatingDrawingBoard-header"
        onResizeStop={(e, direction, ref, delta, position) => {
          // Set the new height/width for the drawing panel.
          setDims({
            width: dims.width + delta.width,
            height: dims.height + delta.height,
          })
          // Set the position of the drawing panel, as it may change depending on which direction the resize happened.
          setCoords(position)
        }}
        onDragStop={(e, d) => {
          setCoords({ x: d.x, y: d.y })
        }}
      >
        <Box
          className="FloatingDrawingBoard-inner"
          sx={{ visibility: hasInitializedPosition ? 'visible' : 'hidden' }}
        >
          <div className="FloatingDrawingBoard-header">
            <span>Drawing Mode</span>
            <span>
              <Tooltip title="Open in new window" arrow>
                <span
                  className="FloatingDrawingBoard-header-control"
                  onClick={() => handleNewWindow(dims.width, dims.height)}
                >
                  <LaunchIcon />
                </span>
              </Tooltip>
              <span className="FloatingDrawingBoard-header-control" onClick={handleClose}>
                <CloseIcon />
              </span>
            </span>
          </div>
          {isOpen && (
            <div className="FloatingDrawingBoard-drawing">
              <ErrorBoundary
                fallback={(e) => (
                  <GenericErrorView
                    error={e}
                    message="An error occurred while opening drawing mode."
                  />
                )}
              >
                {excalidrawEnabled ? (
                  <Suspense fallback={<SuspendedState />}>
                    <Excalidraw
                      name={name}
                      slug={slug}
                      whiteboardBoardIdToCopy={
                        excalidrawEnabled && question ? question?.drawingBoardId : undefined
                      }
                    />
                  </Suspense>
                ) : (
                  <Whiteboard
                    allowInit={isDrawingEnabled}
                    authorId={window.padConfig?.firebaseAuthorId}
                    {...window.padConfig}
                  />
                )}
              </ErrorBoundary>
            </div>
          )}
        </Box>
      </Rnd>
    </Bounds>
  )
}
