import { pack } from '@rrweb/all'
import { record } from '@rrweb/record'
import { eventWithTime } from '@rrweb/types'
import { PlaybackV3VariantType } from 'packs/dashboard/components/PadContext/padConfig'
import { usePadConfigValues } from 'packs/dashboard/components/PadContext/PadContext'
import { useEffect, useRef } from 'react'
import { v4 } from 'uuid'

import { useSessionStorage } from '../../../../../utils'
import { useFetch } from '../../../../../utils/fetch/useFetch'

export function useRecordWorkspace() {
  const fetch = useFetch()
  const { firebaseAuthorId, slug, playbackV3Variant, isPlayback } = usePadConfigValues(
    'isPlayback',
    'firebaseAuthorId',
    'slug',
    'playbackV3Variant'
  )
  const bufferedEvents = useRef<eventWithTime[]>([])
  const [tabId, setTabId] = useSessionStorage<string | null>('tab-id', null)
  const playbackV3RecordingEnabled =
    playbackV3Variant != null && playbackV3Variant !== PlaybackV3VariantType.nothing

  useEffect(() => {
    if (tabId == null) {
      setTabId(v4())
    }
  }, [setTabId, tabId])

  useEffect(() => {
    if (!playbackV3RecordingEnabled || isPlayback) {
      return
    }
    record({
      emit(event: eventWithTime) {
        bufferedEvents.current.push(event)
      },
      packFn: pack,
      recordCanvas: false,
      recordCrossOriginIframes: true,
    })
  }, [isPlayback, playbackV3RecordingEnabled])

  useEffect(() => {
    if (!playbackV3RecordingEnabled) {
      return
    }
    const saveInterval = setInterval(async () => {
      if (
        slug != null &&
        firebaseAuthorId != null &&
        tabId != null &&
        bufferedEvents.current.length > 0
      ) {
        const numEvents = bufferedEvents.current.length
        await fetch(
          `${window.CoderPad.SESSION_RECORDING_SERVICE_URL}/record/${slug}/${firebaseAuthorId}/${tabId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(bufferedEvents.current.slice(0, numEvents)),
          }
        )

        bufferedEvents.current.splice(0, numEvents)
      }
    }, 5000)

    return () => {
      clearInterval(saveInterval)
    }
  }, [fetch, firebaseAuthorId, slug, playbackV3Variant, tabId, playbackV3RecordingEnabled])
}
