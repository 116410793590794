import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  styled,
  Typography,
} from '@mui/material'
import React, { useCallback } from 'react'

import { usePadConfigValue } from '../../../dashboard/components/PadContext/PadContext'
import { useTranscriberContext } from './TranscriberContext'

const ContentBlock = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

export function Consent({ isOpen, requestClose }: { isOpen: boolean; requestClose: () => void }) {
  const { acceptTranscription, denyTranscription, askForAudioDevices } = useTranscriberContext()
  const isOwner = usePadConfigValue('isOwner')

  const decline = useCallback(() => {
    denyTranscription()
    requestClose()
  }, [denyTranscription, requestClose])

  const accept = useCallback(() => {
    acceptTranscription()
    askForAudioDevices()
    requestClose()
  }, [acceptTranscription, askForAudioDevices, requestClose])

  return (
    <Dialog open={isOpen}>
      <DialogTitle>This interview will be recorded to a text transcript</DialogTitle>
      <DialogContent>
        <div>
          <ContentBlock
            sx={{ display: 'flex', alignItems: 'center', fontSize: '1rem', fontWeight: 600 }}
          >
            <HeadsetMicOutlinedIcon fontSize="small" sx={{ mr: (theme) => theme.spacing(1) }} />{' '}
            Please use headphones for accurate transcription.
          </ContentBlock>
          <ContentBlock variant="body1">
            No audio or video of this call will be saved, only a text transcription of the
            conversation.
          </ContentBlock>
          <ContentBlock variant="body1">
            By clicking "Got it" below, you consent to having your conversation recorded to text for
            recruiter review. Our{' '}
            <Link href="https://coderpad.io/privacy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </Link>{' '}
            has processing details. Otherwise click "Continue without transcription".
          </ContentBlock>
        </div>
      </DialogContent>
      <DialogActions>
        <Button key="decline" onClick={decline} variant="outlined" color="inherit">
          Continue without transcription
        </Button>
        <Button key="accept" onClick={accept} variant="contained" color="primary">
          Got it
        </Button>
      </DialogActions>
      {isOwner ? (
        <Typography variant="body1" component="em" sx={{ p: '8px 24px 16px 24px' }}>
          This premium feature is included in your plan for a limited time.
        </Typography>
      ) : null}
    </Dialog>
  )
}
