import _ from 'lodash'

import padConfig from '../pad_config'
import { TABS } from '../RightPane/Header/constants'
import { SandboxShowcaseTypes } from '../Sandbox/constants'

function getDefaultState() {
  // In a function to avoid running this if (!padConfig) e.g. questions page in dashboard
  // TODO: Make separate entrypoints so this won't be a concern
  const defaultStateHasOutput = !!(
    padConfig.execEnabled && CoderPad.LANGUAGES[padConfig.lang]?.execution
  )
  const defaultToNotes = !defaultStateHasOutput && padConfig.notesEnabled
  const filesPresent = !!(
    padConfig.question?.customFiles && padConfig.question.customFiles.length > 0
  )
  const candidateInfoPresent =
    padConfig.question?.candidateInstructions?.[0]?.['instructions']?.length > 0
  const solutionPresent = !!padConfig.question?.solution
  const testsPresent = !!(
    padConfig.question?.testCasesEnabled &&
    padConfig.question.visibleTestCases &&
    padConfig.question.visibleTestCases.length > 0
  )
  const _customDatabaseLanguage = padConfig.question?.customDatabaseLanguage

  const activeTab = padConfig.hasPadSummary
    ? 'summary'
    : defaultToNotes
    ? 'notes'
    : padConfig.report
    ? 'report'
    : candidateInfoPresent
    ? 'candidateInfo'
    : 'output'

  return {
    _customDatabaseId: padConfig.question?.customDatabaseId,
    _customDatabaseLanguage,
    _execEnabled: padConfig.execEnabled,
    _language: padConfig.lang,
    _projectTemplateSlug: null,
    _environmentSlug: null,
    activeTab,
    uiType: padConfig.uiType,
    unseenContent: _.mapValues(TABS, () => false),
    visibleTabs: {
      drawing:
        padConfig.isPlayback &&
        padConfig.uiType !== 'drawing_only' &&
        // `drawingBoardId` indicates there is a drawing in WBEgnine-based DM.
        (padConfig.drawingModified || !!padConfig.drawingBoardId),
      candidateInfo: candidateInfoPresent,
      database: !!(_customDatabaseLanguage && defaultStateHasOutput),
      files: filesPresent,
      notes:
        padConfig.notesEnabled ||
        (padConfig.isSandbox && padConfig.sandboxView === SandboxShowcaseTypes.Interviewer),
      output: defaultStateHasOutput,
      tests: testsPresent && !padConfig.report,
      report: !!padConfig.report,
      solution: solutionPresent,
      requestClient: false,
      // `aiChatEnabled` is the org-wide setting for enabling AI chat
      // `hasAiChat` is controlled by a flag that makes all of a particular user's pads have AI chat
      ai: padConfig.aiChatEnabled || padConfig.hasAiChat,
      // Transcription tab is only available in playback.
      transcript: padConfig.isPlayback && padConfig.hasPlaybackTranscription,
      // Interview Summary tab is only available in playback.
      summary: padConfig.isPlayback && padConfig.hasPadSummary,
      summaryOutline: padConfig.isPlayback && padConfig.hasPadSummaryOutline,
    },
  }
}

const isDBLanguage = (lang) => {
  return lang === 'mysql' || lang === 'postgresql'
}

export default function tabsReducer(state = getDefaultState(), action) {
  switch (action.type) {
    case 'environment_changed': {
      const { environment } = action

      if (environment) {
        const newState = {
          ...state,
          _projectTemplateSlug: environment.projectTemplateSlug,
          _environmentSlug: environment.slug,
        }

        const getOutput = () => {
          if (state.uiType === 'drawing_only') {
            return false
          }

          if (
            environment.projectTemplateSlug &&
            !environment.projectTemplateSlug.startsWith('jupyter')
          ) {
            return true
          }

          if (state._execEnabled && CoderPad.LANGUAGES[environment.language]?.execution) {
            return true
          }

          return false
        }

        const output = getOutput()
        const database = !!(
          output &&
          (environment.customDatabaseLanguage || isDBLanguage(environment.language))
        )

        // Covers the case where a pad has loaded the initial environment and program output is enabled.
        // Absense of an environment slug in the tabs state is the giveaway that we are in that scenario.
        // in playback, don't let this steal tab focus from the Summary
        if (!state._environmentSlug && !state.visibleTabs.summary && output) {
          newState.activeTab = 'output'
        }

        newState.visibleTabs = { ...state.visibleTabs, output, database }

        return newState
      }

      return state
    }

    // We need to listen to this action because its payload determines tab
    // visibility.
    case 'pad_setting_changed': {
      const { key, value } = action
      if (
        ![
          'execEnabled',
          'language',
          'questionId',
          'customDatabaseId',
          'customDatabaseLanguage',
        ].includes(key)
      ) {
        break
      }

      const newState = { ...state }
      switch (key) {
        case 'execEnabled':
          newState._execEnabled = value
          break
        case 'language':
          newState._language = value
          break
        case 'customDatabaseId':
          newState._customDatabaseId = value
          break
        case 'customDatabaseLanguage':
          newState._customDatabaseLanguage = value
          break
      }
      const output =
        newState.uiType !== 'drawing_only' &&
        !!(
          newState._projectTemplateSlug ||
          (newState._execEnabled && CoderPad.LANGUAGES[newState._language]?.execution)
        )

      newState.visibleTabs = {
        ...state.visibleTabs,
        database: !!(
          (newState._customDatabaseLanguage || isDBLanguage(newState._language)) &&
          output
        ),
        output,
      }
      if (!newState.visibleTabs[state.activeTab]) {
        // Give preference to the program output tab if execution is enabled. Otherwise just pick the first.
        newState.activeTab = output ? 'output' : _.findKey(newState.visibleTabs)
      }

      return newState
    }

    case 'set_visibleTab_notes':
      return { ...state, visibleTabs: { ...state.visibleTabs, notes: action.value } }
    case 'set_visibleTab_requestClient': {
      const newState = {
        ...state,
        visibleTabs: { ...state.visibleTabs, requestClient: action.value },
      }
      if (!newState.visibleTabs[newState.activeTab]) {
        newState.activeTab = _.findKey(newState.visibleTabs)
      }
      return newState
    }

    case 'environment_question_changed':
    case 'question_data_loaded':
    case 'question_selected_by_local_user': {
      const output = !!(
        (action.projectTemplateSlug && !action.projectTemplateSlug.startsWith('jupyter')) ||
        (state._projectTemplateSlug && !state._projectTemplateSlug.startsWith('jupyter')) ||
        (state._execEnabled && CoderPad.LANGUAGES[action.language]?.execution)
      )

      const visibleTabs = {
        ...state.visibleTabs,
        candidateInfo: action.candidateInstructions?.[0]?.['instructions']?.length > 0,
        database: !!((action.customDatabaseLanguage || isDBLanguage(action.language)) && output),
        files: action.customFiles && action.customFiles.length > 0,
        output,
        tests: !!action.testCasesEnabled,
        solution: !!action.solution,
      }
      const activeTab =
          visibleTabs.candidateInfo && action.autofocus && !padConfig.isPlayback
          ? 'candidateInfo'
          : visibleTabs[state.activeTab]
          ? state.activeTab
          : // Give preference to the program output tab if execution is enabled. Otherwise just pick the first.
          output
          ? 'output'
          : _.findKey(visibleTabs)
      return {
        ...state,
        activeTab,
        visibleTabs,
        _customDatabaseId: action.customDatabaseId,
        _customDatabaseLanguage: action.customDatabaseLanguage,
        _language: action.language,
        unseenContent: {
          ...state.unseenContent,
          // Highlight new tabs.
          candidateInfo:
            visibleTabs.candidateInfo && activeTab !== 'candidateInfo' && !action.autofocus,
          database: visibleTabs.database && activeTab !== 'database',
          files: visibleTabs.files && activeTab !== 'files',
        },
      }
    }

    case 'package_changed':
      if (!action.databaseLanguage || action.databaseLanguage === state._customDatabaseLanguage) {
        return state
      } else {
        return {
          ...state,
          _customDatabaseLanguage: action.databaseLanguage,
          _language: action.language,
          unseenContent: {
            ...state.unseenContent,
            database: !state.visibleTabs.database,
          },
          visibleTabs: {
            ...state.visibleTabs,
            database: true,
          },
        }
      }

    case 'tab_selected':
      return {
        ...state,
        activeTab: action.tab,
        customFileSelected: action.customFileSelected ?? state.customFileSelected, // Keep the selected file while changing tab to prevent reloading its data
        unseenContent: {
          ...state.unseenContent,
          [action.tab]: false,
        },
      }

    case 'run_clicked':
    case 'local_user_ran_code':
      // When you're the one to hit run, switch tab to program output so you can
      // see what you did.
      return {
        ...state,
        activeTab: 'output',
        unseenContent: {
          ...state.unseenContent,
          output: false,
        },
      }

    case 'console_output_produced':
    case 'markdown_content_changed':
    case 'html_rendered':
      if (state.activeTab === 'output') break

      // Program output was produced but you don't see it because you're on a
      // different tab.
      return {
        ...state,
        unseenContent: {
          ...state.unseenContent,
          output: true,
        },
      }

    case 'instructions_added':
      return {
        ...state,
        unseenContent: {
          ...state.unseenContent,
          candidateInfo: state.activeTab !== 'candidateInfo',
        },
      }
  }
  return state
}

// Tab Actions
export function visibleTabNoteToggled(status) {
  return {
    type: 'set_visibleTab_notes',
    value: status,
  }
}

export function visibleTabRequestClientToggled(isVisible) {
  return {
    type: 'set_visibleTab_requestClient',
    value: isVisible,
  }
}

export function setSlectedTab(tab) {
  return {
    type: 'tab_selected',
    tab,
  }
}
