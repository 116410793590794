import { Sparkles } from '@codinpad/shared-components/components/icons/index.js'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Box, Button, Collapse, Paper, styled, Typography } from '@mui/material'
import { isSuccessState } from 'graphql/queryStates'
import { PadSummary } from 'graphql/types'
import React, { FC, useState } from 'react'

import { useMemoizedOrganizationHookMaker } from '../../../../graphql/hooks/organization/useOrganization/useOrganization'
import { PadAnalyticsEvent } from '../../constants/PadAnalyticsEvent'
import Markdown from '../../markdown_output'
import trackEvent from '../../track_event'

const Container = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const GradientOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '100px',
  background: `linear-gradient(to bottom, transparent, ${theme.palette.tokens.surface.page})`,
}))

const SummaryContainer = styled(Box)(({ theme }) => ({
  '.MarkdownOutput': {
    lineHeight: 1.3,
    fontSize: '0.875rem',

    // Colorize the section and sub-section titles.
    'h2:not(:first-of-type)': {
      marginTop: theme.spacing(2),
    },
    h2: {
      marginBottom: theme.spacing(1),
      color: theme.palette.tokens.coderpad[400],
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    h3: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
    ul: {
      marginTop: 0,
      marginBottom: 0,
      paddingLeft: theme.spacing(3),
    },
  },
}))

const AdSpaceGrid = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(2),
  overflow: 'hidden',
}))

const AdSection: React.FC<{ title: string; featurePoints: string[] }> = ({
  title,
  featurePoints,
}) => {
  return (
    <Box>
      <Typography
        variant="body1"
        fontWeight="bold"
        sx={{ color: (theme) => theme.palette.tokens.coderpad[400] }}
      >
        {title}
      </Typography>
      <Box component="ul" sx={{ paddingLeft: (theme) => theme.spacing(3) }}>
        {featurePoints.map((point, index) => (
          <li key={index}>
            <Typography variant="body2">{point}</Typography>
          </li>
        ))}
      </Box>
    </Box>
  )
}

export const TranscriptSummary: FC<{
  padSummary: PadSummary
}> = ({ padSummary }) => {
  const [collapsed, setIsCollapsed] = useState(true)

  const { organization, status } = useMemoizedOrganizationHookMaker(`
    transcriptionsEnabled
    transcriptionsFeatureAccessible
    transcriptionSummaryEnabled
  `)()

  // Block rendering on getting organization info to prevent showing inaccurate data.
  if (!isSuccessState(status)) {
    return null
  }

  const shouldPitchTranscriptionFeature = !organization?.transcriptionsEnabled
  const shouldPitchTranscriptionSummaryFeature = !organization?.transcriptionSummaryEnabled

  let adSpaceContent = null
  if (shouldPitchTranscriptionFeature || shouldPitchTranscriptionSummaryFeature) {
    const reasonMsg = !organization?.transcriptionsFeatureAccessible ? (
      'Upgrade your plan to enable Transcription and Interview Highlights'
    ) : !organization?.transcriptionsEnabled ? (
      <>
        Transcription and Interview Highlights are turned off. We suggest you turn them on in{' '}
        <a href="/dashboard/organization/settings" target="_blank">
          Settings
        </a>
      </>
    ) : (
      <>
        Interview Highlights are turned off. We suggest you turn them on in{' '}
        <a href="/dashboard/organization/settings" target="_blank">
          Settings
        </a>
      </>
    )

    adSpaceContent = (
      <Box>
        <p>{reasonMsg}</p>
        <p>We will analyze and summarize the transcript of the interview for you.</p>
        <Paper sx={{ padding: (theme) => theme.spacing(2) }}>
          <Box display="flex" justifyContent="center" position="relative">
            <Sparkles
              sx={{ height: '20px', width: '20px', flex: 'none', position: 'absolute', left: 0 }}
            />
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              AI Interview Highlights includes the following
            </Typography>
          </Box>
          <AdSpaceGrid>
            <AdSection
              title="Introduction"
              featurePoints={[
                'Summary of introductions',
                'Discussion of candidate experience',
                'Details about role and company',
                'Format of interview',
              ]}
            />
            <AdSection
              title="Interview Details"
              featurePoints={[
                'Presentation of question',
                'Skills evaluated',
                'Candidate code, debugging, and refactors',
                'Candidate solves the problem?',
              ]}
            />
            <AdSection
              title="Communication"
              featurePoints={[
                "Candidate's clarifying questions",
                'Candidate thought process',
                "Interviewer's assistance or hints",
                'Follow up questions',
              ]}
            />
          </AdSpaceGrid>
        </Paper>
      </Box>
    )
  }

  return (
    <Container>
      {adSpaceContent != null ? (
        adSpaceContent
      ) : (padSummary.transcriptSummary ?? '').length === 0 ? (
        <Typography variant="body1">
          No Interview Highlights were generated for this interview.
        </Typography>
      ) : (
        <>
          <Collapse in={!collapsed} collapsedSize={'30vh'} sx={{ position: 'relative' }}>
            <SummaryContainer>
              <Markdown value={padSummary.transcriptSummary} hidden={false} />
            </SummaryContainer>
            {collapsed && <GradientOverlay />}
          </Collapse>
          <Box display="flex" justifyContent="center">
            <Button
              size="small"
              color="inherit"
              variant="text"
              onClick={() => {
                setIsCollapsed((isCollapsed) => !isCollapsed)
                trackEvent(PadAnalyticsEvent.TranscriptionSummaryShowMoreButtonClicked)
              }}
              endIcon={collapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
              sx={{ marginTop: (theme) => theme.spacing(1) }}
            >
              {collapsed ? 'Show More' : 'Show less'}
            </Button>
          </Box>
        </>
      )}
    </Container>
  )
}
