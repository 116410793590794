import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Button } from '@mui/material'
import React, { useCallback } from 'react'

export const CopyButton: React.FC<{
  text: string | (() => string)
  onClick?: () => void
  message?: string
}> = ({ text, onClick, message = 'Copy' }) => {
  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(typeof text === 'function' ? text() : text)
    onClick?.()
  }, [text, onClick])

  return (
    <Button
      onClick={copyToClipboard}
      variant="outlined"
      startIcon={<ContentCopyIcon />}
      size="small"
      color="inherit"
    >
      {message}
    </Button>
  )
}
