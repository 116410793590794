export const PadAnalyticsEvent = {
  PlaybackAdSpaceClicked: 'playback ad space clicked',
  SandboxCreatePadFromSandboxButtonClicked: 'sandbox create pad button clicked',
  PlaybackUserTrackInteracted: 'pad playback user track interacted',
  PlaybackScrubberInteracted: 'pad playback scrubber interacted',
  PlaybackButtonClicked: 'pad playback button clicked',

  RightPaneTabClicked: 'right pane tab clicked',
  ProjectOutputTabClicked: 'project output tab clicked',
  RequestClientHistoryItemClicked: 'request client history item clicked',

  RequestClientRequestSent: 'request client request sent',
  RequestClientRequestCanceled: 'request client request canceled',

  FileTreeUploadedFile: 'file tree uploaded file',
  InstructionsWindowRefocus: 'instructions window refocus',
  InstructionsWindowOpen: 'instructions window open',

  CreateQuestionFromTabAffirmation: 'Create Question From Tab Affirmed',
  CreateQuestionFromTabCancel: 'Create Question From Tab Cancelled',
  PadInvitesEmailInviteSent: 'Invite Sent',
  PadSettingsOpened: 'Settings Modal Opened',

  ProjectMiniBrowserOpenNewWindow: 'Project Mini Browser Open New Window',

  DrawingModeWindowOpen: 'Drawing Mode Window Open',
  DrawingModeModalClose: 'Drawing Mode Modal Close',
  DrawingModeWindowRefocus: 'Drawing Mode Window Refocus',
  DrawingModeModalOpen: 'Drawing Mode Modal Open',

  LanguageSearchResultSelected: 'Searchbar Language Selected',

  TranscriptionUserAgreed: 'Transcription User Agreed',
  TranscriptionUserDisagreed: 'Transcription User Disagreed',
  TranscriptionPlaybackSearched: 'Transcription Playback Searched',
  TranscriptionPlaybackLineClicked: 'Transcription Playback Line Clicked',
  TranscriptionUserPaused: 'Transcription User Paused',
  TranscriptionUserUnpaused: 'Transcription User Unpaused',
  TranscriptionUserStopped: 'Transcription User Stopped',

  QuestionBankAddToPad: 'Question Bank Add To Pad',
  QuestionBankFilterChanged: 'Question Bank Filter Changed',
  QuestionBankQuestionTypeChanged: 'Question Bank Question Type Changed',

  PadSummaryCopyButtonClicked: 'Pad Summary Copy Button Clicked',
  PadSummaryOutlineCopyButtonClicked: 'Pad Summary Outline Copy Button Clicked',
  TranscriptionSummaryCopyButtonClicked: 'Transcription Summary Copy Button Clicked',
  TranscriptionSummaryShowMoreButtonClicked: 'Transcription Summary Show More Button Clicked',
} as const

export type PadAnalyticsEvent = typeof PadAnalyticsEvent[keyof typeof PadAnalyticsEvent]
