// Order here determines how they will render in the UI.
export const TABS = {
  summary: 'Interview Summary',
  summaryOutline: 'Outline',
  report: 'Report',
  candidateInfo: 'Instructions',
  output: 'Program Output',
  requestClient: 'API Requests',
  database: 'Database',
  files: 'Files',
  tests: 'Test Cases',
  notes: 'Private Interviewer Notes',
  drawing: 'Drawing',
  solution: 'Solutions',
  ai: 'AI Assist',
  transcript: 'Transcript',
}
