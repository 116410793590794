import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { useFetch } from '../../../../utils/fetch/useFetch'
import { usePadConfigValues } from '../../../dashboard/components/PadContext/PadContext'

interface QuestionFileSummary {
  id: string
  title: string
  filename: string
  filesize: string
}
interface CandidateInstruction {
  default_visible: boolean
  instructions: string
}
interface TestCase {
  id: number
  arguments: string[]
  return_value: string
}
interface QuestionVariant {
  id: number
  display: string
  language: string
  project_template_slug: string
  solution: string
}
export interface QuestionSummary {
  id: number | string
  questionId: number | string
  language: string
  testCasesEnabled: boolean
  customDatabaseId?: string
  customDatabaseLanguage?: string
  customDatabaseSchema?: string
  customFiles?: QuestionFileSummary[]
  drawingBoardId?: string
  starterCodeByLanguage?: Record<string, string>
  candidateInstructions?: CandidateInstruction[]
  visibleTestCases?: TestCase[]
  solution?: string
  title?: string
  questionVariants?: QuestionVariant[]
}

/**
 * Fetches extra question information for the active environment's question, if applicable. Also keeps a cache
 * of questions fetched, so that we can avoid refetching question information as users bounce between envs.
 */
export function useEnvironmentQuestion(questionId?: number | string) {
  const cache = useRef({})
  const fetch = useFetch()

  const [q, setQ] = useState<QuestionSummary | null>(null)

  const { isOwner, isQuestionDraftPreview, slug, takeHome } = usePadConfigValues(
    'isOwner',
    'isQuestionDraftPreview',
    'slug',
    'takeHome'
  )
  const { takeHomeOpened } = useSelector((state) => state.padSettings)

  useEffect(() => {
    setQ(null)
    if (questionId) {
      if (cache.current[questionId]) {
        setQ(cache.current[questionId])
      } else if (isOwner || !takeHome || takeHomeOpened) {
        fetch(`/${slug}/question/${questionId}${isQuestionDraftPreview ? '?preview=1' : ''}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((resp) => {
            return resp.json()
          })
          .then((questionInfo) => {
            if (questionInfo?.id) {
              const qSummary = {
                id: questionId,
                questionId,
                customDatabaseId: questionInfo.custom_database_id,
                customDatabaseLanguage: questionInfo.custom_database_language,
                customDatabaseSchema: questionInfo.custom_database_schema,
                customDatabaseSchemaJson: questionInfo.custom_database_schema_json,
                customFiles: questionInfo.custom_files,
                drawingBoardId: questionInfo.drawing_board_id,
                language: questionInfo.language,
                testCasesEnabled: questionInfo.test_cases_enabled,
                starterCodeByLanguage: questionInfo.starter_code_by_language,
                candidateInstructions: questionInfo.candidate_instructions,
                visibleTestCases: questionInfo.visible_test_cases,
                solution: questionInfo.solution,
                title: questionInfo.title,
                questionVariants: questionInfo.question_variants,
              }
              cache.current[questionId] = qSummary
              setQ(qSummary)
            }
          })
      }
    }
  }, [fetch, isOwner, isQuestionDraftPreview, questionId, slug, takeHome, takeHomeOpened])

  return q
}
